import React from "react"
import styled from "styled-components"
import GatsbyImage from "gatsby-image"

// Componnets
import PyramidProgress from "../../PyramidProgress"

// Constants
import { xmlNamespace } from "../../../constants/svgUrl"

// Styling
import colors from "../../../styles/colors"
import { fontWeights } from "../../../styles/textStyles"
import mq from "../../../styles/breakpoints"
import { fonts } from "../../../styles/fonts"

// Types
import { IServiceTemplateIntroProps } from "../../../prismic-types/templates/services-template"
import { ServicesIntroLargeSvg } from "../ServicesSvgs"

interface IProps {
  hero: IServiceTemplateIntroProps
}

const Intro: React.FC<IProps> = ({ hero }) => {
  const commonProps = {
    width: 49,
    height: 37,
    fill: "none",
    xmlns: xmlNamespace,
    viewBox: "0 0 49 37",
    color: colors.main["500"],
  }

  return (
    <>
      <Container>
        <IntroWrapper>
          <InnerWrapper>
            <TextWrapper>
              <LabelWrapper>
                <LabelSection background_color={hero.serviceTypeColor}>
                  <Label>{hero.serviceType}</Label>
                </LabelSection>

                <StyledTraingleProgress>
                  <PyramidProgress
                    level={hero.serviceLevel}
                    commonProps={commonProps}
                  />
                </StyledTraingleProgress>
              </LabelWrapper>

              {hero.title && <Title> {hero.title} </Title>}
              {hero.subHeading && <SubTitle>{hero.subHeading}</SubTitle>}
              {hero.description && <Text>{hero.description}</Text>}
            </TextWrapper>
            <ImageWrapper mirrored={false}>
              {hero.heroImage && (
                <GatsbyImage
                  fluid={hero.heroImage.fluid}
                  alt={hero.heroImage.alt}
                />
              )}
            </ImageWrapper>
          </InnerWrapper>
        </IntroWrapper>
      </Container>
      <ServicesIntroLargeSvg style={{ width: "100%", height: "100%" }} />
    </>
  )
}

const Container = styled.section`
  width: 100%;
  background-color: ${colors.main["700"]};
  margin: 0 auto;
  display: flex;
  padding: 66px 0 0px;
  flex-direction: column;
  align-items: center;

  ${mq.from.L`
    padding: 86px 0 0px;
  `}
`

const IntroWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  padding: 80px 24px;
  gap: 8px;

  ${mq.from.S`
    padding: 80px 48px;
  `}

  ${mq.from.L`
    padding: 80px 88px;
  `}

  ${mq.from.XL`
    max-width: 1440px;
  `}
`

const InnerWrapper = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;

  ${mq.from.S`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-items: center;
  `}
`

const ImageWrapper = styled.div<{ mirrored?: boolean }>`
  width: 100%;
  order: 0;

  img {
    margin-bottom: 0;
  }

  ${mq.from.S`
    width: calc(50% - 15px);
    margin-bottom: 0;
    order: ${({ mirrored }: { mirrored?: boolean }) => (mirrored ? 0 : 2)};
  `}
`

const TextWrapper = styled.div<{ line?: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  order: 1;
  gap: 12px;

  ${mq.from.S`
    width: calc(50% - 16px);
  `}
`

const LabelWrapper = styled.div`
  display: flex;
  gap: 12px;
`

const LabelSection = styled.span<{ background_color: string }>`
  display: flex;
  border-radius: 6px;
  padding: 8px 16px;
  gap: 10px;
  background-color: ${({ background_color }) => background_color};
`

const Label = styled.h4`
  font-family: ${fonts.primary};
  font-size: 16px;
  line-height: 16px;
  font-weight: ${fontWeights.medium};
  letter-spacing: 2px;
  text-transform: uppercase;
  color: ${colors.white};
  margin-bottom: 0px;
`

const StyledTraingleProgress = styled.span`
  & svg {
    display: none;

    ${mq.from.XSM`
      display: block;
    `}
  }
`

const Title = styled.h1`
  font-size: 24px;
  line-height: 34px;
  font-weight: ${fontWeights.bold};
  letter-spacing: -1px;
  color: ${colors.main["300"]};
  margin-bottom: 0px;

  ${mq.from.M`
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -1.5px;
    color: ${colors.main["100"]};
  `}
`

const SubTitle = styled.h5`
  font-family: ${fonts.primary};
  font-size: 32px;
  line-height: 44px;
  font-weight: ${fontWeights.bold};
  color: ${colors.main["100"]};
  margin-bottom: 0px;

  ${mq.from.M`
    font-size: 22px;
    line-height: 30px;
    font-weight: ${fontWeights.medium};
    color: ${colors.main["300"]};
  `}
`

const Text = styled.p`
  font-size: 18px;
  line-height: 28px;
  font-weight: ${fontWeights.regular};
  letter-spacing: -1%;
  color: ${colors.main["200"]};
  margin-bottom: 0px;

  ${mq.from.M`
    font-size: 18px;
    line-height: 30px;
    max-width: 459px;
  `}
`

export default Intro
