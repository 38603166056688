import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

// Components
import ContentWrapper from "../components/ContentWrapper"
import TitleDescriptionImage from "../components/slices/TitleDescriptionImage"
import QuoteSlice from "../components/slices/QuoteSlice"
import TextDescriptionColumns from "../components/slices/TextDescriptionColumns"
import StackColumnsContent from "../components/slices/StackColumnsContent"
import Intro from "../components/services/services-template/Intro"
import CardSection from "../components/services/services-template/CardSection"
import CTASlice from "../components/slices/CTASlice"

// Styles
import colors from "../styles/colors"
import mq from "../styles/breakpoints"

const ServiceTemplate = ({ data }: any) => {
  const raw = data.prismicServicesTemplate.data
  const hero = {
    title: raw.title,
    heading: raw.heading,
    subHeading: raw.heading,
    description: raw.description,
    serviceType: raw.service_type,
    serviceTypeColor: raw.service_type_color,
    serviceLevel: raw.service_level,
    heroImage: raw.hero_image,
  }

  const card = {
    cardTitle: raw.card_title,
    cardDescription: raw.card_description,
    cardButton: raw.card_button,
    cardColumns: raw.card_columns.map((column: any) => ({
      cardTitle: column.card_title1,
      cardDescription: column.card_description1,
    })),
  }

  const ctaSlices = raw.body.filter(
    (slice: any) =>
      slice.slice_type === "team_member_v2" ||
      slice.slice_type === "services_cards" ||
      slice.slice_type === "resources_cards" ||
      slice.slice_type === "deep_dive_cards"
  )

  return (
    <ContainerWrapper>
      {/* Hero Section */}
      <Intro hero={hero} />
      <Container>
        <ContentWrapperStyled>
          <InnerWrapper>
            <InnerContentWidth>
              <CardSection card={card} />
              {raw.body.map((slice: any) => (
                <>
                  {slice.slice_type === "titledescriptionimage" && (
                    <TitleDescriptionImage
                      key={slice.slice_type}
                      primary={{
                        align: slice.primary.align,
                        description1: slice.primary.TBTDIDescription.html,
                        title: slice.primary.title.raw,
                        subtitle: slice.primary.subtitle,
                        image_position: slice.primary.image_position,
                        image: slice.primary.image,
                      }}
                    />
                  )}
                  {slice.slice_type === "quote" && (
                    <QuoteSlice
                      key={slice.slice_type}
                      primary={{
                        title: slice.primary.title.raw,
                        subtitle: slice.primary.subtitle,
                      }}
                    />
                  )}
                  {slice.slice_type === "textdescriptioncolumns" && (
                    <TextDescriptionColumns
                      key={slice.slice_type}
                      primary={{
                        columnsPerRow: slice.primary.columns_per_row,
                      }}
                      items={slice.items.map((item: any) => ({
                        title: item.title.raw,
                        description1: item.description1.html,
                      }))}
                    />
                  )}
                  {slice.slice_type === "stackcolumnscontent" && (
                    <StackColumnsContent
                      key={slice.slice_type}
                      items={slice.items.map((item: any) => ({
                        image: item.image,
                        title: item.title.raw,
                        description1: item.description1.html,
                      }))}
                    />
                  )}
                </>
              ))}
            </InnerContentWidth>
          </InnerWrapper>
          <BottomSection>
            {ctaSlices.map((slice: any) => (
              <CTASlice slice={slice} />
            ))}
          </BottomSection>
        </ContentWrapperStyled>
      </Container>
    </ContainerWrapper>
  )
}

export const query = graphql`
  query servicesTemplate($uid: String!, $locale: String!) {
    prismicServicesTemplate(uid: { eq: $uid }, lang: { eq: $locale }) {
      uid
      data {
        title
        heading
        description
        service_type
        service_type_color
        service_level
        card_title
        card_description
        card_button
        card_columns {
          card_description1
          card_title1
        }
        hero_image {
          fluid {
            aspectRatio
            base64
            src
            srcSet
            srcSetWebp
            srcWebp
            sizes
          }
        }
        body {
          ... on PrismicServicesTemplateBodyTitledescriptionimage {
            id
            slice_type
            primary {
              subtitle
              title {
                raw
              }
              align
              TBTDIDescription: description1 {
                html
              }
              image_position
              image {
                fluid {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                }
                alt
              }
            }
          }
          ... on PrismicServicesTemplateBodyStackcolumnscontent {
            id
            slice_type
            items {
              description1 {
                html
              }
              title {
                raw
              }
              image {
                alt
                fluid {
                  aspectRatio
                  base64
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                  sizes
                }
              }
            }
          }
          ... on PrismicServicesTemplateBodyTextdescriptioncolumns {
            id
            slice_type
            primary {
              columns_per_row
            }
            items {
              description1 {
                html
              }
              title {
                raw
              }
            }
          }
          ... on PrismicServicesTemplateBodyQuote {
            id
            slice_type
            primary {
              subtitle
              title {
                raw
              }
            }
          }
          ... on PrismicServicesTemplateBodyTeamMemberV2 {
            id
            slice_type
            primary {
              description1
              heading1
              team_member {
                document {
                  ... on PrismicTeamMember {
                    id
                    data {
                      name
                      role
                      photo {
                        fluid {
                          aspectRatio
                          base64
                          sizes
                          src
                          srcSet
                          srcSetWebp
                          srcWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicServicesTemplateBodyServicesCards {
            id
            slice_type
            primary {
              services_cards_button_label
              services_cards_button_link
              services_cards_heading {
                raw
              }
            }
            items {
              services_cards_template {
                document {
                  ... on PrismicServicesTemplate {
                    id
                    uid
                    data {
                      title
                      service_type
                      service_level
                      service_type_color
                      heading
                      description
                      thumbnail_image {
                        alt
                        fluid {
                          aspectRatio
                          base64
                          sizes
                          src
                          srcSet
                          srcSetWebp
                          srcWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicServicesTemplateBodyResourcesCards {
            slice_type
            primary {
              resources_cards_button_link
              resources_cards_heading {
                raw
              }
              resources_cards_label
            }
            items {
              resources_cards_template {
                document {
                  ... on PrismicResourceTemplate {
                    uid
                    data {
                      heading {
                        text
                      }
                      description {
                        text
                      }
                      image {
                        alt
                        url
                        fluid {
                          aspectRatio
                          base64
                          sizes
                          src
                          srcSet
                          srcSetWebp
                          srcWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicServicesTemplateBodyDeepDiveCards {
            id
            slice_type
            primary {
              button_label
              button_link
              deep_dive_description
              deep_dive_heading {
                raw
                text
              }
            }
            items {
              deep_dive_template {
                id
                type
                uid
                document {
                  ... on PrismicDeepDiveTemplate {
                    id
                    data {
                      heading {
                        text
                        raw
                      }
                      description
                      heading_color
                      foreground_color
                      background_color
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ServiceTemplate

const ContainerWrapper = styled.div`
  background-color: ${colors.main["900"]};
`

const Container = styled.section`
  position: relative;
  padding: 0px 0 80px;
  color: ${colors.text};

  ${mq.from.L`
    padding: 0px 0 80px;
  `}
`

const ContentWrapperStyled = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 80px;
`

const InnerWrapper = styled.div`
  padding: 80px 0px 0px 0px;
  margin 0 24px;

  ${mq.from.S`
    margin: 0 auto;
    padding: 80px 48px 0px 48px;
  `}
`

const InnerContentWidth = styled.div`
  display: flex;
  flex-direction: column;
  gap: 96px;
  max-width: 1062px;
  margin: 0 auto;
`

const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
  padding: 0px 24px;

  ${mq.from.S`
    padding: 0px 48px;
  `}

  ${mq.from.XL`
    padding: 0px 88px;
  `}
`
